<template>
  <span v-if="rbn || notfound">{{ callsign }}<slot></slot></span>
  <span v-else-if="!userStats || !stats"
    ><router-link :to="`/profile/${sanatizedCallsign}`">{{
      callsign
    }}</router-link
    ><slot></slot
  ></span>
  <span v-else>
    <v-menu open-on-hover offset-x close-delay="250">
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on" v-if="!nobadge && enablePoints">
          <v-badge
            v-if="!nobadge"
            inline
            :dark="true"
            label="score"
            :content="String(stats.score)"
          >
            <v-avatar size="20" v-if="!noavatar">
              <v-img :src="gravatar"></v-img>
            </v-avatar>
            {{ callsign }}
          </v-badge>
        </span>
        <span v-bind="attrs" v-on="on" v-else>
          <v-avatar size="20" v-if="!noavatar">
            <v-img :src="gravatar"></v-img>
          </v-avatar>
          {{ callsign }}
        </span>
        <slot></slot>
      </template>

      <v-card max-width="350px">
        <v-list dense>
          <v-list-item link :to="profile_route">
            <v-list-item-avatar size="75">
              <v-img :src="gravatar"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="title">{{
                stats.callsign
              }}</v-list-item-title>
              <v-list-item-subtitle v-if="stats.name && stats.name != ''">{{
                stats.name
              }}</v-list-item-subtitle>
              <v-list-item-subtitle v-if="stats.qth && stats.qth != ''">{{
                stats.qth
              }}</v-list-item-subtitle>
              <v-list-item-subtitle v-if="enablePoints"
                >{{ stats.score }} total points</v-list-item-subtitle
              >
              <v-list-item-content
                ><div class="subtitle-2">VIEW PROFILE</div></v-list-item-content
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>Activator</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-subtitle
              >{{ stats.activator.activations }} successful activations
              <sub v-if="enablePoints"
                >&times; 50 points</sub
              ></v-list-item-subtitle
            >
          </v-list-item>
          <v-list-item>
            <v-list-item-subtitle
              >{{ stats.activator.parks }} unique parks
              <sub v-if="enablePoints"
                >&times; 250 points</sub
              ></v-list-item-subtitle
            >
          </v-list-item>
          <v-list-item>
            <v-list-item-subtitle
              >{{ stats.activator.qsos }} QSOs
              <sub v-if="enablePoints"
                >&times; 2 points</sub
              ></v-list-item-subtitle
            >
          </v-list-item>
          <v-list-item>
            <v-list-item-title>Hunter</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-subtitle
              >{{ stats.hunter.parks }} unique parks
              <sub v-if="enablePoints"
                >&times; 25 points</sub
              ></v-list-item-subtitle
            >
          </v-list-item>
          <v-list-item>
            <v-list-item-subtitle
              >{{ stats.hunter.qsos }} QSOs
              <sub v-if="enablePoints"
                >&times; 1 points</sub
              ></v-list-item-subtitle
            >
          </v-list-item>
          <v-list-item>
            <v-list-item-title
              ><span v-if="enablePoints">Bonus</span
              ><span v-else>Achievements</span></v-list-item-title
            >
          </v-list-item>
          <v-list-item>
            <v-list-item-subtitle
              >{{ stats.awards }} awards earned
              <sub v-if="enablePoints"
                >&times; 250 points</sub
              ></v-list-item-subtitle
            >
          </v-list-item>
          <v-list-item>
            <v-list-item-subtitle
              >{{ stats.endorsements }} endorsements earned
              <sub v-if="enablePoints"
                >&times; 100 points</sub
              ></v-list-item-subtitle
            >
          </v-list-item>
          <!--<v-list-item>
            <v-list-item-subtitle>{{stats.comments}} comments <sub v-if="enablePoints">&times; 10 points</sub></v-list-item-subtitle>
          </v-list-item>-->
        </v-list>
      </v-card>
    </v-menu>
  </span>
</template>

<script>
import axios from "axios";

export default {
  //name: 'user-stats',
  props: {
    callsign: {
      type: String,
      required: true,
    },
    nobadge: {
      type: Boolean,
      required: false,
      default: false,
    },
    noavatar: {
      type: Boolean,
      required: false,
      default: false,
    },
    rbn: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    userStats: {
      get() {
        return this.$store.state.config.userStats;
      },
    },
    gravatar: {
      get() {
        return `https://www.gravatar.com/avatar/${this.stats.gravatar}?s=150&d=identicon&r=g`;
      },
    },
    profile_route: {
      get() {
        return `/profile/${this.callsign.match(/^[A-Z\d]+/)}`;
      },
    },
    enablePoints: {
      get() {
        return this.$store.state.global.enablePoints;
      },
    },
    sanatizedCallsign: {
      get() {
        return this.callsign.match(/^[A-Z\d]+/);
      },
    },
  },
  data() {
    return { loading: true, stats: null, notfound: false };
  },
  watch: {
    callsign: {
      deep: true,
      handler: function (newVal, oldVal) {
        if (newVal != oldVal && this.userStats) {
          this.loading = true;
          this.notfound = false;
          this.stats = null;
          this.loadStats();
        }
      },
    },
  },
  methods: {
    setStats(stats) {
      this.stats = stats;
      this.loading = false;
    },
    loadStats() {
      if (!this.userStats) return;

      if (this.rbn) {
        this.loading = false;
        this.notfound = true;
        return;
      }

      var sc = this.callsign.match(/^[A-Z\d]{1,3}\d[A-Z\d]*/i);

      if (!sc || sc[0] == "") {
        this.loading = false;
        this.notfound = true;
        return;
      }

      // trust browser cache to prevent repeated requests to server?
      axios.get(`https://${process.env.VUE_APP_API_URL}/stats/user/${sc}`).then(
        (response) => {
          this.stats = response.data;
          this.loading = false;
        },
        (error) => {
          console.log(error); // Now error is used
          this.notfound = true;
        }
      );
    },
  },
  created() {
    this.loadStats();
  },
};
</script>
